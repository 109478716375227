// Seacoast overrides

body{
  font-weight: 300;
}
.nowrap{
  white-space: nowrap;
}
.float-left
{
  float: left;
}
.striped:nth-of-type(odd)
{
  background-color: #e9ecef;
}
.cat-image
{
  width: 40px;
  height: 40px;
}
.fits-all
{
  overflow-x: hidden;
  white-space: nowrap;
  transform-origin: center left;
}
ul{
  margin-left: 1em;
  list-style-position: inside;
}
.a{
  cursor: pointer;
}
.grid
{
  height: 100%;
}
.price
{
  font-weight: 600;
  color: #2f6100;
}
.click-all
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
h2{
  margin-top: 1.618em;
}
.working {
  position: fixed;
  top: 1em;
  left: 1em;
}
.hide {
  display: none;
}
* {
  padding: 0;
  margin: 0;
  font-family: Lato, sans-serif;
}
.navbar {
  background: #40832e;
}
.nav-item a {
  color: #fff;
}
.navbar-brand img {
  height: 75px;
}
.sub-nav {
  width: 100%;
  height: 70px;
  background: url("/vendor/seacoast/img/background.jpg") repeat-x;
  box-shadow: 0 2 5 0 rgba(204, 202, 204, 1);
}
.menu-wrapper {
  position: relative;
  height: 70px;
  margin: 0 auto;
  overflow: hidden;
}
.container
{
  margin-top: 1.618em;
}
.menu {
  box-sizing: border-box;
  height: 120px;
  margin-right: 60px;
  margin-left: 60px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.menu .item {
  box-sizing: border-box;
  display: inline-block;
  width: 140px;
  height: 100%;
  padding: .4em;
  padding-top: .8em;
}
.item:hover {
  opacity: .8;
}
.item img {
  float: left;
  width: 40px;
}
.item a {
  color: #fff;
}
.cat-title {
  float: right;
  width: 75px;
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-all;
  white-space: normal;
}
.paddle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3em;
  color: #fff;
  background: transparent;
  border: 0;
}
.left-paddle {
  left: 0;
}
.right-paddle {
  right: 0;
}
.hidden {
  display: none;
}
.print {
  max-width: 700px;
  margin: auto;
}
.print span {
  display: inline-block;
  width: 100px;
}
.inline {
  display: inline;
}

.stuck {
  position: sticky;
  top: -1px;
  left: -1px;
  z-index: 5;
  font-family: tahoma, arial, sans-serif;
  background: #737373;
}

.stuck-bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: #737373;
}


/* FOOTER CSS */
footer{
  width: 100%;
  height: auto;
  font-size: 16px;
  color: #fff;
  background-color: #373737;
}
footer .container.row{
  padding: 5px;
  margin: 0 auto;
}
footer ul{
  list-style: none;
}
footer .row{
  padding-top: 20px;
  margin-right: 0;
  margin-left: 10px;
}
footer a{
  color: #fff;
}
footer a:hover{
  color: #c9c9c9;
  text-decoration: none;
}
.col-md-3 a img{
  width: 50px;
  padding-top: 30px;
}
.col-md-3 a img:hover{
  opacity: .8;
}
.col-md-3 ul li{
  padding-top: 10px;
  margin-left: 20px;
}
.copyright p{
  padding-bottom: 10px;
  margin: 0;
  margin-right: 40px;
  text-align: right;
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.verbiage {
  box-sizing: border-box;
  max-width: 750px;

  p {
    margin-bottom: 1rem;
    font-size: larger;
    font-weight: 300;
    line-height: 1.9;
  }
}

.prod_desc {
  margin-top: 3em;
}

@media screen and (max-width: 768px) {
  .verbiage {
    padding-right: .5em;
    padding-left: .5em;
  }
}


@media print {
  body {
    font-size: smaller !important;
  }
  .admin {
    display: none !important;
  }
  nav {
    display: none !important;
  }
  .noprint {
    display: none !important;
  }
  .sub-nav {
    display: none !important;
  }
}

.with-sidebar {
  display: grid;
  grid-gap: 2em;
  grid-template-areas: "body sidebar";
  grid-template-columns: 5fr 3fr;

}
@media screen and (max-width: 993px) {
  .with-sidebar {
    display: grid;
    grid-gap: 2em;
    grid-template-areas:
      "body"
      "sidebar";
    grid-template-columns: 1fr;
  }
}

.the-body {
  grid-area: body;
}

.the-sidebar {
  grid-area: sidebar;
}
